
export const environment = {
  production: false,
  UserPoolId: 'eu-west-2_qR9Rozx9D',
  ClientId: '2112rjp1ned2kg1p90g16ammdn',
  baseUrl: 'https://h1l3p1d070.execute-api.eu-west-2.amazonaws.com/dev/',
  stage: 'dev',
  region: 'eu-west-2',
  bucketUrl: 'https://touch-reports-dev.media.s3.eu-west-2.amazonaws.com'
};
